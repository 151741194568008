import React, { FC, PropsWithChildren, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { breakpoints } from '@ads/front-core/constants/breakpoints'
import { setIsMobile } from '@ads/front-core/store/reducers/app/setIsMobileReducer'
import { fetchConfig } from '@ads/front-core/store/sagas/fetchConfigSaga'
import { useSurvicateScript } from './useSurvicateScript'
import { useRelease } from './useRelease'
import { storeUTMSource } from './utils'

const Page: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch()

  useSurvicateScript()

  useRelease()

  useEffect(() => {
    const onResize = () => {
      dispatch(setIsMobile(window.innerWidth <= breakpoints.MOBILE_PX))
    }

    window.addEventListener('resize', onResize)
    onResize()

    dispatch(fetchConfig())

    storeUTMSource()

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return <>{children}</>
}

export default Page
