/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Created } from '../models/Created'
import type { Error } from '../models/Error'
import type { RequestCreateFeed } from '../models/RequestCreateFeed'
import type { RequestCreateShopOrDraft } from '../models/RequestCreateShopOrDraft'
import type { RequestUpdateFeed } from '../models/RequestUpdateFeed'
import type { RequestUpdateShop } from '../models/RequestUpdateShop'
import type { RequestUpdateShopAdmin } from '../models/RequestUpdateShopAdmin'
import type { ResponseCreateShopOrDraft } from '../models/ResponseCreateShopOrDraft'
import type { ResponseFeed } from '../models/ResponseFeed'
import type { ResponsePixelEvents } from '../models/ResponsePixelEvents'
import type { ResponsePixelStatus } from '../models/ResponsePixelStatus'
import type { ResponseShop } from '../models/ResponseShop'
import type { ResponseShopFeeds } from '../models/ResponseShopFeeds'
import type { ResponseShopLogo } from '../models/ResponseShopLogo'
import type { ResponseSimpleShop } from '../models/ResponseSimpleShop'
import type { ResponseSimpleShopAdmin } from '../models/ResponseSimpleShopAdmin'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ShopService {
  /**
   * Zwraca listę sklepów dla danego użytkownika
   * @returns ResponseSimpleShop dane sklepów
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getShops(): CancelablePromise<
    Array<ResponseSimpleShop> | Error
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/shop',
    })
  }

  /**
   * Tworzy nowy sklep albo draft w przypadku braku uprawnień
   * @returns ResponseCreateShopOrDraft dane zapisane
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static addShopOrDraft({
    requestBody,
  }: {
    /**
     * Dane sklepu
     */
    requestBody: RequestCreateShopOrDraft
  }): CancelablePromise<ResponseCreateShopOrDraft | Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/shop-or-draft',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Zwraca listę sklepów dla danego adminstratora
   * @returns ResponseSimpleShopAdmin dane sklepów
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getAdminShops({
    page,
    limit,
    clientIds,
  }: {
    /**
     * numer strony do pobrania
     */
    page: number
    /**
     * liczba elementów do pobrania
     */
    limit: number
    /**
     * identyfikatory klientów dla których pobieramy sklepy
     */
    clientIds?: Array<number>
  }): CancelablePromise<Array<ResponseSimpleShopAdmin> | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/shop',
      query: {
        clientIds: clientIds,
        page: page,
        limit: limit,
      },
    })
  }

  /**
   * Zwraca dane sklepu o wybraym identyfikatorze (dane na formularz edycji/prezentacji sklepu)
   *
   * @returns ResponseShop dane sklepu
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getShopById({
    shopId,
    fetchHbpData,
  }: {
    shopId: number
    /**
     * czy pobierać dane adresowe z HBP
     */
    fetchHbpData?: boolean
  }): CancelablePromise<ResponseShop | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/shop/{shopId}',
      path: {
        shopId: shopId,
      },
      query: {
        fetchHbpData: fetchHbpData,
      },
    })
  }

  /**
   * Modyfikuje dane sklepu
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static modifyShop({
    shopId,
    requestBody,
  }: {
    shopId: number
    /**
     * Dane sklepu do modyfikacji
     */
    requestBody: RequestUpdateShop
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/shop/{shopId}',
      path: {
        shopId: shopId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Kasuje sklep (a raczej ustawia jego status na removed)
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static deleteShop({
    shopId,
  }: {
    shopId: number
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/shop/{shopId}',
      path: {
        shopId: shopId,
      },
    })
  }

  /**
   * pobiera liste feedow dla danego sklepu
   *
   * @returns ResponseShopFeeds status feeda
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getShopFeeds({
    shopId,
  }: {
    shopId: number
  }): CancelablePromise<ResponseShopFeeds | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/shop/{shopId}/feed',
      path: {
        shopId: shopId,
      },
    })
  }

  /**
   * tworzy feed dla danego sklepu
   *
   * @returns Error unexpected error
   * @returns Created feed stworzony
   * @throws ApiError
   */
  public static feed({
    shopId,
    requestBody,
  }: {
    shopId: number
    /**
     * Dane potrzebne do stworzenia nowego feedu
     */
    requestBody?: RequestCreateFeed
  }): CancelablePromise<Error | Created> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/shop/{shopId}/feed',
      path: {
        shopId: shopId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * modyfikuje (i wstrzumuje odpalone kampanie) feed
   *
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static updateFeed({
    shopId,
    requestBody,
  }: {
    shopId: number
    /**
     * Dane potrzebne do zmodyfikowaia feedu
     */
    requestBody?: RequestUpdateFeed
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/shop/{shopId}/feed',
      path: {
        shopId: shopId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Wgrywa logo sklepu
   * @returns ResponseShopLogo url loga
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static uploadShopLogo({
    shopId,
    formData,
  }: {
    shopId: number
    /**
     * Dane sklepu do modyfikacji
     */
    formData?: {
      logo?: Blob
    }
  }): CancelablePromise<ResponseShopLogo | Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/shop/logo/{shopId}',
      path: {
        shopId: shopId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
    })
  }

  /**
   * Modyfikuje dane sklepu dla administratora
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static updateAdminShop({
    shopId,
    requestBody,
  }: {
    shopId: number
    /**
     * Dane sklepu do modyfikacji
     */
    requestBody: RequestUpdateShopAdmin
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/admin/shop/{shopId}',
      path: {
        shopId: shopId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Ukrywa sklep(sklep jest niewidoczny w panelu klienta)
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static adminHideShop({
    shopId,
  }: {
    shopId: number
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/admin/shop/{shopId}/hide',
      path: {
        shopId: shopId,
      },
    })
  }

  /**
   * Tworzy bazę  CDP dla sklepu
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static createCdpDatabase({
    shopId,
  }: {
    shopId: number
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/shop/{shopId}/cdpDatabase',
      path: {
        shopId: shopId,
      },
    })
  }

  /**
   * Ustawia sklep jako widoczny(sklep jest znów widoczny w panelu klienta)
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static adminUnhideShop({
    shopId,
  }: {
    shopId: number
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/admin/shop/{shopId}/unhide',
      path: {
        shopId: shopId,
      },
    })
  }

  /**
   * zwraca informacje o eventach pixela
   *
   * @returns ResponsePixelEvents eventy pixela
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getPxEvents({
    shopId,
    dayCount,
  }: {
    shopId: number
    /**
     * ilość dni z których zwracane mają być eventy
     */
    dayCount: number
  }): CancelablePromise<ResponsePixelEvents | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/shop/{shopId}/pixel-events',
      path: {
        shopId: shopId,
      },
      query: {
        dayCount: dayCount,
      },
    })
  }

  /**
   * zwraca informacje o statusie reguł pixela
   *
   * @returns ResponsePixelStatus status reguł pixela
   * @throws ApiError
   */
  public static getPixelStatus({
    shopId,
  }: {
    shopId: number
  }): CancelablePromise<ResponsePixelStatus> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/shop/{shopId}/pixel',
      path: {
        shopId: shopId,
      },
    })
  }

  /**
   * zwraca informacje o statusie parsowania wszystkich feedów związanych z ID
   *
   * @returns ResponseFeed status feeda
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getFeedStatus({
    feedId,
  }: {
    feedId: string
  }): CancelablePromise<ResponseFeed | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/feed/{feedId}',
      path: {
        feedId: feedId,
      },
    })
  }
}
