import {
  ResponseAvailableCampaignTypes,
  ResponseCampaignRunningConditions,
  ResponseCampaignToArchive,
  ResponseClient,
  ResponseGetIABCategories,
  ResponseShop,
  ResponseShopFeeds,
  ResponseStudioTemplates,
  ResponseUserClients,
  ResponseGetPushConsentFormWithTemplates,
} from '@ads/front-core/api'
import {
  AllProductTypes,
  Campaign,
  ClientBalance,
} from '@ads/front-core/interfaces'
import { RootState, preloadedRootState } from '@ads/front-core/store/rootState'
import { SidebarProps } from '@ads/front-core/components/SidebarFixed'
import { SegmentsStore } from 'components/Targeting/TargetSegments/interfaces'
import {
  IFormBase as ICreationForm,
  IFormData as ICreationFormData,
} from 'components/views/Creations/RichMedia'
import { IForm as INewsletterForm } from 'components/views/Newsletter/Creator/interfaces'
import {
  IForm as IFormTemplates,
  FormStore,
} from 'components/views/MAPushConsentCreator'
import { FormStatus } from 'components/views/MAPushConsentForm/interfaces'
import {
  Category,
  Group,
  Mode,
} from 'components/views/CampaignCreate/Wizard/interface'

export interface AppState extends RootState {
  shop: Record<number, ResponseShop>
  feeds: {
    isLoading: boolean
    list?: ResponseShopFeeds
  }
  client: {
    currentClientId: number
    currentShopId: number
    balance?: ClientBalance
    list: ResponseUserClients
    currentClient?: ResponseClient
    currentClientShops?: ResponseShop[]
  }
  campaign: {
    availableCampaignTypes: Record<number, ResponseAvailableCampaignTypes>
    availableCampaignTypesLoading: boolean
    campaignsToArchive?: ResponseCampaignToArchive[]
    campaign?: Campaign
    campaignSegments?: SegmentsStore
    campaignIAB?: ResponseGetIABCategories
    runningConditions: Record<string, ResponseCampaignRunningConditions>
    runCampaignAfterSave?: boolean
  }
  form: {
    status?: FormStatus
    templates: FormStore[]
    serviceWorkerValidation?: ResponseGetPushConsentFormWithTemplates['serviceWorkerValidation']
    stats?: ResponseGetPushConsentFormWithTemplates['stats']
    addTemplatesAfterSave: boolean
  }
  common: {
    isOpenLeadModal: boolean
    isOpenPromotion1000Rules: boolean
    isOpenPromotion1000About: boolean
    newsletterListUrl?: string
    newsletterPreviewTemplate?: ResponseStudioTemplates[number]['template']
    forceSelectTab?: SidebarProps['selectedTab']
  }
  draft: {
    form: ResponseGetPushConsentFormWithTemplates['pushConsentFormConfig']
    formTemplates: IFormTemplates
    campaign: Campaign
    newsletter: INewsletterForm
    creation: ICreationForm<ICreationFormData>
  }
  wizard: {
    mode: Mode
    group: Group
    category: Category
    productType: AllProductTypes
  }
}

export const preloadedState: AppState = {
  ...preloadedRootState,
  shop: {},
  feeds: {
    isLoading: false,
    list: undefined,
  },
  client: {
    currentClientId: 0,
    currentShopId: 0,
    list: [],
    balance: {
      blockedFunds: 0,
      monthLimit: 0,
      monthlyBalance: 0,
      balance: 0,
      isLoading: true,
    },
    currentClient: undefined,
  },
  campaign: {
    availableCampaignTypes: {},
    availableCampaignTypesLoading: false,
    runningConditions: {},
  },
  form: {
    templates: undefined,
    addTemplatesAfterSave: false,
  },
  draft: {
    form: undefined,
    formTemplates: undefined,
    campaign: undefined,
    newsletter: undefined,
    creation: undefined,
  },
  common: {
    isOpenLeadModal: false,
    isOpenPromotion1000Rules: false,
    isOpenPromotion1000About: false,
  },
  wizard: {
    mode: undefined,
    category: undefined,
    group: undefined,
    productType: undefined,
  },
}
