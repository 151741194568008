import { uniq } from 'lodash'
import {
  ResponseGenericCampaign,
  RequestGenericCampaignCalculator,
  VideoCampaignProperties,
  FlexibleGenericCampaignProperties,
  GenericCampaignType,
} from '@ads/front-core/api'

type GenericCampaignMapToCalculator = (
  campaign: ResponseGenericCampaign,
) => RequestGenericCampaignCalculator

const calculationDefault: GenericCampaignMapToCalculator = genericCampaign => ({
  type: genericCampaign.type as any,
  budget: genericCampaign.budget,
})

const calculationNative: GenericCampaignMapToCalculator = genericCampaign => ({
  type: 'NATIVE',
  budget: genericCampaign.budget,
  targeting: genericCampaign.targeting,
})

const calculationVideo: GenericCampaignMapToCalculator = genericCampaign => {
  const properties = genericCampaign.properties as VideoCampaignProperties
  return {
    type: 'VIDEO',
    budget: genericCampaign.budget,
    duration: properties?.creations?.[0]?.duration,
    budgetModel: genericCampaign.budgetModel,
  }
}

const calculationMix: GenericCampaignMapToCalculator = genericCampaign => ({
  type: 'MIX',
  budget: genericCampaign.budget,
  creationTypes: uniq(
    genericCampaign.properties?.creations?.map(item => item.type),
  ),
})

const calculationCommercialBreak: GenericCampaignMapToCalculator = genericCampaign => {
  const properties = genericCampaign.properties as FlexibleGenericCampaignProperties
  return {
    type: 'COMMERCIAL_BREAK',
    budget: genericCampaign.budget,
    format: properties?.format,
    configurationType: properties?.configurationType,
  }
}

const calculationBroadFlex: GenericCampaignMapToCalculator = genericCampaign => {
  const properties = genericCampaign.properties as FlexibleGenericCampaignProperties
  return {
    type: 'BROAD_FLEX',
    budget: genericCampaign.budget,
    format: properties?.format,
    configurationType: properties?.configurationType,
  }
}

const calculationWPFlex: GenericCampaignMapToCalculator = genericCampaign => {
  const properties = genericCampaign.properties as FlexibleGenericCampaignProperties
  return {
    type: 'WP_FLEX',
    budget: genericCampaign.budget,
    format: properties?.format,
    startDate: genericCampaign.startDate,
    endDate: genericCampaign.endDate,
  }
}

const calculationGigaboard: GenericCampaignMapToCalculator = genericCampaign => ({
  type: 'GIGABOARD',
  budget: genericCampaign.budget,
})

const GENERIC_CAMPAIGNS_CALCULATORS: Record<
  RequestGenericCampaignCalculator['type'],
  GenericCampaignMapToCalculator
> = {
  NATIVE: calculationNative,
  VIDEO: calculationVideo,
  MIX: calculationMix,
  COMMERCIAL_BREAK: calculationCommercialBreak,
  STICKY: calculationDefault,
  BAR: calculationDefault,
  BAR_LP_WP: calculationDefault,
  CONTENT_BOX: calculationDefault,
  BUSINESS_SERVICES: calculationDefault,
  SPORTS_SERVICES: calculationDefault,
  WEB_PORTALS: calculationDefault,
  FLOATING_HALFPAGE: calculationDefault,
  ARTICLE_SPONSORED_GUARANTEED: calculationDefault,
  BROAD_FLEX: calculationBroadFlex,
  WP_FLEX: calculationWPFlex,
  GIGABOARD: calculationGigaboard,
}

export const getGenericCampaignCalculatorRequestBody = (
  genericCampaign: Pick<
    ResponseGenericCampaign,
    | 'type'
    | 'budget'
    | 'properties'
    | 'budgetModel'
    | 'targeting'
    | 'startDate'
    | 'endDate'
  >,
): RequestGenericCampaignCalculator =>
  GENERIC_CAMPAIGNS_CALCULATORS[genericCampaign.type](genericCampaign)

export const allowGenericCampaignCalculation = (
  type: GenericCampaignType,
): boolean => type in GENERIC_CAMPAIGNS_CALCULATORS
