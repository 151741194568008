import React from 'react'
import { initReactI18next } from 'react-i18next'
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import IconEn from '@ads/front-core/Icons/IconEn'
import IconPl from '@ads/front-core/Icons/IconPl'
import { SupportedLanguages } from '@ads/front-core/translations-api'
import en from './en/translation.json'
import pl from './pl/translation.json'
import cz from './cz/translation.json'
import de from './de/translation.json'
import ITranslations from './interface'

export const resources = {
  en,
  pl,
  cz,
  de,
}

export type TSupportedLanguages = keyof SupportedLanguages
export type TTranslationsKeys = keyof ITranslations

export type TLanguages = {
  lang: TSupportedLanguages
  nameToDisplay: string
  nameToDisplayUCFirst: string
  icon?: React.ReactElement
  isHidden?: boolean
}

export const languages: TLanguages[] = [
  {
    lang: 'pl',
    nameToDisplay: 'polski',
    nameToDisplayUCFirst: 'Polski',
    icon: <IconPl />,
  },
  {
    lang: 'en',
    nameToDisplay: 'english',
    nameToDisplayUCFirst: 'English',
    icon: <IconEn />,
  },
  {
    lang: 'cz',
    nameToDisplay: 'cesky',
    nameToDisplayUCFirst: 'Česky',
    icon: <IconEn />,
    isHidden: true,
  },
  {
    lang: 'de',
    nameToDisplay: 'deutsch',
    nameToDisplayUCFirst: 'Deutsch',
    icon: <IconEn />,
    isHidden: true,
  },
]

export const i18n = i18next

const DEFAULT_LANGUAGE: TSupportedLanguages = 'pl'

if (typeof window !== 'undefined') {
  i18n.use(LanguageDetector)
}

i18n.use(initReactI18next).init({
  supportedLngs: languages
    .filter(language => !language.isHidden)
    .map(language => language.lang),
  fallbackLng: DEFAULT_LANGUAGE,
  detection: {
    caches: ['cookie'],
  },
  resources,
  interpolation: {
    escapeValue: false,
  },
})

export const currentLanguage = (i18n.language ||
  DEFAULT_LANGUAGE) as TSupportedLanguages
export const currentLanguageUpperCase = currentLanguage?.toUpperCase()

export const decimalSeparator = currentLanguage === 'pl' ? ',' : '.'

export default i18n
