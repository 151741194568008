/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreationRichMediaSubtype } from '../models/CreationRichMediaSubtype'
import type { Error } from '../models/Error'
import type { RequestChangeCreationTemplateArchiveStatus } from '../models/RequestChangeCreationTemplateArchiveStatus'
import type { RequestCreateCreationFromTemplate } from '../models/RequestCreateCreationFromTemplate'
import type { RequestCreateStudioCreation } from '../models/RequestCreateStudioCreation'
import type { RequestCreation } from '../models/RequestCreation'
import type { RequestCreationContentZip } from '../models/RequestCreationContentZip'
import type { RequestCreationTemplate } from '../models/RequestCreationTemplate'
import type { RequestCreationUpdate } from '../models/RequestCreationUpdate'
import type { RequestCreationVariantEnable } from '../models/RequestCreationVariantEnable'
import type { RequestPreviewCreationContent } from '../models/RequestPreviewCreationContent'
import type { RequestPreviewStudioCreation } from '../models/RequestPreviewStudioCreation'
import type { RequestSetCreationAdminSettings } from '../models/RequestSetCreationAdminSettings'
import type { RequestUpdateStudioCreation } from '../models/RequestUpdateStudioCreation'
import type { RequestUpdateStudioCreationTemplate } from '../models/RequestUpdateStudioCreationTemplate'
import type { RequestUpdateTemplate } from '../models/RequestUpdateTemplate'
import type { ResponseCreateAnyCreations } from '../models/ResponseCreateAnyCreations'
import type { ResponseCreation } from '../models/ResponseCreation'
import type { ResponseCreationTemplate } from '../models/ResponseCreationTemplate'
import type { ResponseCreationTemplatesList } from '../models/ResponseCreationTemplatesList'
import type { ResponseCreationWithPreviewUrls } from '../models/ResponseCreationWithPreviewUrls'
import type { ResponseEntityCreated } from '../models/ResponseEntityCreated'
import type { ResponseFindSimilarCreations } from '../models/ResponseFindSimilarCreations'
import type { ResponseStudioTemplates } from '../models/ResponseStudioTemplates'
import type { ResponseUploadImage } from '../models/ResponseUploadImage'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class CreationService {
  /**
   * ustawia ustawnia administracyjne dla kreacji
   * @returns void
   * @throws ApiError
   */
  public static setCreationAdminSettings({
    creationId,
    requestBody,
  }: {
    /**
     * identyfikator kreacji reklamowej
     */
    creationId: number
    requestBody?: RequestSetCreationAdminSettings
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/admin/creation/{creationId}/admin-settings',
      path: {
        creationId: creationId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Włącza/wyłącza poszczególne warianty kreacji. Aktualnie obsługiwane tylko dla natywek
   * @returns void
   * @throws ApiError
   */
  public static creationsVariantEnable({
    creationId,
    requestBody,
  }: {
    /**
     * identyfikator kreacji reklamowej
     */
    creationId: number
    /**
     * opcje dla poszczególnych wariantów
     */
    requestBody?: RequestCreationVariantEnable
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/creation/{creationId}/enableVariants',
      path: {
        creationId: creationId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * modyfikuje kreacje po stronie użytkownika
   * @returns ResponseCreateAnyCreations kreacje zmodyfikowane
   * @throws ApiError
   */
  public static creationsUpdate({
    requestBody,
  }: {
    /**
     * Dane kreacji
     */
    requestBody: Array<RequestCreationUpdate>
  }): CancelablePromise<ResponseCreateAnyCreations> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/campaign/creations/update',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Włącza kreacje po stronie użytkownika
   * @returns void
   * @throws ApiError
   */
  public static creationsEnable({
    creationsIds,
  }: {
    /**
     * identyfikatory kreacji reklamowej
     */
    creationsIds?: Array<number>
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/campaign/creations/enable',
      query: {
        creationsIds: creationsIds,
      },
    })
  }

  /**
   * wyłącza kreacje po stronie użytkownika
   * @returns void
   * @throws ApiError
   */
  public static creationsDisable({
    creationsIds,
  }: {
    /**
     * identyfikatory kreacji reklamowej
     */
    creationsIds?: Array<number>
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/campaign/creations/disable',
      query: {
        creationsIds: creationsIds,
      },
    })
  }

  /**
   * Usuwa kreacje
   * @returns any kreacje usunęte
   * @throws ApiError
   */
  public static creationsDelete({
    creationsIds,
  }: {
    /**
     * identyfikatory kreacji reklamowej
     */
    creationsIds?: Array<number>
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/campaign/creations/delete',
      query: {
        creationsIds: creationsIds,
      },
    })
  }

  /**
   * Pobiera pełną listę kreacji dla kampanii
   * @returns ResponseCreationWithPreviewUrls Podgląd kampanii roas
   * @throws ApiError
   */
  public static creationsByCampaign({
    campaignId,
  }: {
    campaignId: number
  }): CancelablePromise<Array<ResponseCreationWithPreviewUrls>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/campaign/{campaignId}/creation/',
      path: {
        campaignId: campaignId,
      },
    })
  }

  /**
   * tworzy wszystkie typy kreacji
   * @returns ResponseCreateAnyCreations dane zuploadowanych fotek
   * @throws ApiError
   */
  public static creationsCreate({
    campaignId,
    requestBody,
  }: {
    campaignId: number
    requestBody?: Array<RequestCreation>
  }): CancelablePromise<ResponseCreateAnyCreations> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/campaign/{campaignId}/creations/',
      path: {
        campaignId: campaignId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * podglad kreacji
   * @returns string Podgląd kreacji
   * @throws ApiError
   */
  public static previewCreationContent({
    requestBody,
  }: {
    /**
     * dane kreacji
     */
    requestBody?: RequestPreviewCreationContent
  }): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/creation/preview',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * tworzy studio kreacji na podstawie szablonu dla kampanii lub newslettera
   * @returns Error unexpected error
   * @returns ResponseEntityCreated studio utworzone
   * @throws ApiError
   */
  public static createStudio({
    requestBody,
  }: {
    /**
     * Dane studia kreacji
     */
    requestBody: RequestCreateStudioCreation
  }): CancelablePromise<Error | ResponseEntityCreated> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/studio',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * modyfikuje studio kreacji
   * @returns Error unexpected error
   * @returns ResponseEntityCreated studio utworzone
   * @throws ApiError
   */
  public static updateStudio({
    creationId,
    requestBody,
  }: {
    /**
     * identyfikator kreacji reklamowej
     */
    creationId: number
    /**
     * Dane studia kreacji
     */
    requestBody: RequestUpdateStudioCreation
  }): CancelablePromise<Error | ResponseEntityCreated> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/studio/{creationId}',
      path: {
        creationId: creationId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * modyfikuje kreację zip po stronie użytkownika
   * @returns void
   * @throws ApiError
   */
  public static creationZipUpdate({
    creationId,
    requestBody,
  }: {
    /**
     * identyfikator kreacji reklamowej
     */
    creationId: number
    /**
     * Dane kreacji
     */
    requestBody?: RequestCreationContentZip
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/zip-creation/{creationId}',
      path: {
        creationId: creationId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Pobiera dane wybranej kreacji
   * @returns ResponseCreation Dane kreacji
   * @throws ApiError
   */
  public static creationById({
    creationId,
  }: {
    /**
     * identyfikator kreacji reklamowej
     */
    creationId: number
  }): CancelablePromise<ResponseCreation> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/creation/{creationId}',
      path: {
        creationId: creationId,
      },
    })
  }

  /**
   * pobiera podobne kreacje
   * @returns ResponseFindSimilarCreations podobne kreacje
   * @throws ApiError
   */
  public static findSimilarCreations({
    creationsIds,
  }: {
    /**
     * identyfikatory kreacji reklamowej
     */
    creationsIds?: Array<number>
  }): CancelablePromise<ResponseFindSimilarCreations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/creation/similar',
      query: {
        creationsIds: creationsIds,
      },
    })
  }

  /**
   * pobiera liste szablonow
   * @returns ResponseCreationTemplatesList lista szablonow
   * @throws ApiError
   */
  public static getCreationTemplatesList({
    page,
    limit,
    clientId,
    sortDir,
    sortBy = 'name',
    width,
    height,
    archive,
    type,
    name,
  }: {
    /**
     * numer strony do pobrania
     */
    page: number
    /**
     * liczba elementów do pobrania
     */
    limit: number
    clientId: number
    /**
     * kierunek sortowania
     */
    sortDir?: 'asc' | 'desc'
    /**
     * pole po którym będą sortowane wyniki zapytania
     */
    sortBy?: 'date' | 'name' | 'type'
    /**
     * dziala tylko w polaczaniu w parametrem height
     */
    width?: number
    /**
     * dziala tylko w polaczaniu w paramterem width
     */
    height?: number
    /**
     * czy pobrac archiwalne
     */
    archive?: boolean
    /**
     * typ kreacji
     */
    type?: CreationRichMediaSubtype
    /**
     * nazwa
     */
    name?: string
  }): CancelablePromise<ResponseCreationTemplatesList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/creation-template',
      query: {
        sortDir: sortDir,
        page: page,
        limit: limit,
        clientId: clientId,
        sortBy: sortBy,
        width: width,
        height: height,
        archive: archive,
        type: type,
        name: name,
      },
    })
  }

  /**
   * tworzy szablon kreacji
   * @returns void
   * @throws ApiError
   */
  public static createCreationTemplate({
    requestBody,
  }: {
    requestBody?: RequestCreationTemplate
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/creation-template',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * pobiera szablon kreacji
   * @returns ResponseCreationTemplate szablon kreacji
   * @throws ApiError
   */
  public static getCreationTemplate({
    creationId,
  }: {
    /**
     * identyfikator kreacji reklamowej
     */
    creationId: number
  }): CancelablePromise<ResponseCreationTemplate> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/creation-template/{creationId}',
      path: {
        creationId: creationId,
      },
    })
  }

  /**
   * aktualizuje szablon kreacji
   * @returns any szablon zaktualizowany
   * @throws ApiError
   */
  public static updateCreationTemplate({
    creationId,
    requestBody,
  }: {
    /**
     * identyfikator kreacji reklamowej
     */
    creationId: number
    requestBody?: RequestUpdateTemplate
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/creation-template/{creationId}',
      path: {
        creationId: creationId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * zmienia status szablonu
   * @returns any status zmieniony
   * @throws ApiError
   */
  public static changeCreationTemplateArchiveStatus({
    creationId,
    requestBody,
  }: {
    /**
     * identyfikator kreacji reklamowej
     */
    creationId: number
    requestBody?: RequestChangeCreationTemplateArchiveStatus
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/creation-template/{creationId}/archive',
      path: {
        creationId: creationId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * tworzy kreacje z szalbonu
   * @returns void
   * @throws ApiError
   */
  public static createCreationFromTemplate({
    campaignId,
    requestBody,
  }: {
    campaignId: number
    requestBody?: RequestCreateCreationFromTemplate
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/campaign/{campaignId}/creation-template',
      path: {
        campaignId: campaignId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * podgląd studia kreacji
   * @returns string Podgląd kreacji
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static studioPreviewById({
    creationId,
  }: {
    /**
     * identyfikator kreacji reklamowej
     */
    creationId: number
  }): CancelablePromise<string | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/studio/{creationId}/preview',
      path: {
        creationId: creationId,
      },
    })
  }

  /**
   * podgląd kreacji zip
   * @returns binary Plik kreacji zwrócony
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static zipPreviewById({
    creationId,
  }: {
    /**
     * identyfikator kreacji reklamowej
     */
    creationId: number
  }): CancelablePromise<Blob | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/creation/{creationId}/zip-preview',
      path: {
        creationId: creationId,
      },
    })
  }

  /**
   * uploaduje zdjęcie dla newsletter
   * @returns ResponseUploadImage url do obrazka
   * @throws ApiError
   */
  public static uploadImageForNewsletter({
    creationId,
    formData,
  }: {
    /**
     * identyfikator kreacji reklamowej
     */
    creationId: number
    formData?: {
      image?: Blob
    }
  }): CancelablePromise<ResponseUploadImage> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/studio/{creationId}/upload',
      path: {
        creationId: creationId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
    })
  }

  /**
   * pogląd edycji studia kreacji
   * @returns string Podgląd kreacji
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static studioContentPartsPreview({
    requestBody,
  }: {
    /**
     * Dane studia kreacji
     */
    requestBody: RequestPreviewStudioCreation
  }): CancelablePromise<string | Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/studio/preview',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * pobiera szablony dla studia kreacji
   * @returns ResponseStudioTemplates Szablony kreacji
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getStudioTemplates({
    forNewsletter,
  }: {
    /**
     * identyfikator kreacji reklamowej
     */
    forNewsletter?: boolean
  }): CancelablePromise<ResponseStudioTemplates | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/studio/templates',
      query: {
        forNewsletter: forNewsletter,
      },
    })
  }

  /**
   * zmiana szablonu w studiu kreacji
   * @returns void
   * @throws ApiError
   */
  public static studioUpdateTemplate({
    creationId,
    requestBody,
  }: {
    /**
     * identyfikator kreacji reklamowej
     */
    creationId: number
    /**
     * Template
     */
    requestBody?: RequestUpdateStudioCreationTemplate
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/studio/{creationId}/template',
      path: {
        creationId: creationId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
