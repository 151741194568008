import { createAction } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'
import {
  IFormBase as ICreationForm,
  IFormData as ICreationFormData,
} from 'components/views/Creations/RichMedia'
import { AppState } from '../../appState'

export const setCreationDraft = createAction<ICreationForm<ICreationFormData>>(
  'draft/setCreationDraft',
)

export const setCreationDraftReducer = (
  state: AppState['draft'],
  action: ReturnType<typeof setCreationDraft>,
): void => {
  state.creation = cloneDeep(action.payload)
}
