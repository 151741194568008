export const storeUTMSource = () => {
  if (!window.location.search) {
    return
  }

  try {
    const sourceUTMs = new URLSearchParams(
      window.localStorage.getItem('source') || '',
    )
    const search = new URLSearchParams(window.location.search)
    if (search?.get('utm_source') === 'samito') {
      sourceUTMs.set('utm_ma', search.get('utm_campaign'))
    }

    Array.from(search)
      .filter(s => s[0].match(/^utm_(source|campaign)/))
      .forEach(item => {
        if (!sourceUTMs.get(item[0])) {
          sourceUTMs.set(item[0], item[1])
        }
        sourceUTMs.set(`lc_${item[0]}`, item[1])
      })

    const source = sourceUTMs.toString()
    if (source) {
      window.localStorage.setItem('source', source)
    }
  } catch (e) {
    console.error(e)
  }
}
