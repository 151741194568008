import React, { FC, PropsWithChildren } from 'react'
import { Helmet } from 'react-helmet'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { set as uniformSet } from '@wp-atfd/uniform'
import { theme } from '@ads/front-ds'
import { OpenAPI } from '@ads/front-core/api/core/OpenAPI'
import { OpenAPI as OpenAPITranslations } from '@ads/front-core/translations-api/core/OpenAPI'
import AdblockInfo from '@ads/front-core/components/AdblockInfo'
import OneLoginProvider from '@ads/front-core/components/App/OneLoginProvider'
import Toasts from '@ads/front-core/components/Toasts'
import { createAppStore } from 'store/store'
import './layout.css'
import Page from './page'

OpenAPI.BASE = '/api'
OpenAPITranslations.BASE = '/translations-api'

uniformSet('ads.wp.pl')

const Layout: FC<PropsWithChildren> = ({ children }) => (
  <ThemeProvider theme={theme}>
    <Provider store={createAppStore()}>
      <OneLoginProvider>
        <Helmet>
          <title>WP Ads</title>
        </Helmet>
        <Page>
          <AdblockInfo />
          {children}
          <div id="modal" />
          <Toasts />
        </Page>
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-N2XRX3Z"
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          ></iframe>
        </noscript>
      </OneLoginProvider>
    </Provider>
  </ThemeProvider>
)

export default Layout
